import { useStaticQuery, graphql } from "gatsby";
import { defaultArea } from "@constants";
import _ from "lodash";

export const getPriceStr = ( price ) => {
  let priceStr = '';
  if (price.min && price.max) {
      priceStr = " between " + `£` + addCommas(price.min) + " and " + `£` + addCommas(price.max);
  } else if (price.max) {
      priceStr = " under " + `£` + addCommas(price.max);
  } else if (price.min) {
      priceStr = " over " + `£` + addCommas(price.min);
  }
  return priceStr;
}

function addCommas(num) {
  var str = num.toString().split('.');
  if (str[0].length >= 4) {
      // add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

export const ACTION_TYPES = {
  sales: " for sale ",
  lettings: " to rent ",
}

export const pTypeBase = {
  sales: "/international-property-for-sale/",
  lettings: "/international-property-for-sale/",
}

export const buildingTypes = {
    "apartment" : "apartments",
    "detached-house" : "detached houses",
    "semi-detached-house": "semi detached houses",
    "terraced-house": "terraced houses",
    "bungalow": "bungalows",
    "other" : "other",
    "flat": "flats",
    "townhouse": "townhouses"
}

export const toTitleCase = (text) => {
  let _text = text || '';
  if ( _text ) {
    _text = _text.trim().split(' ');
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text.replaceAll(' And ', ' and ');
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location) => {
  if (location) {
      var query = '';
      let pathUri_main = location.split(pTypeBase[pType].slice(0, -1))
      var areaVal = ''
      var bedVal = ''
      var minpriceVal = ''
      var maxpriceVal = ''
      var typeVal = ''
      var mustIncludeVal = ''
      var radiusVal = 3
      var sortVal = ''

      if( pathUri_main[1] ) { // following could be regexp
        let pathUri = pathUri_main[1].split('/');

        //lets loop
        for(let vi = 1; vi <= pathUri.length; vi++ ) {
          // check for area
          if( typeof pathUri[vi] === "undefined" ) {continue;}
          // for-sale
          if ( pathUri[vi].indexOf("for-sale") >= 0 ) {
            // its area
            pType = 'sales';
          }
          if ( pathUri[vi].indexOf("to-rent") >= 0 ) {
            // its area
            pType = 'lettings';
          }
          if ( pathUri[vi].indexOf("in-") == 0 ) {
            // its area
            areaVal = pathUri[vi].replace("in-","").replaceAll("-"," ");
          }
          if ( pathUri[vi].indexOf("-and-more-") >= 0 ) {
            // its bedrooms
            bedVal = pathUri[vi].replace("-and-more-bedrooms","")
          }
          if ( pathUri[vi].indexOf("studio") >= 0 ) {
            // its studio bedroom
            bedVal = 0
          }
          if ( pathUri[vi].indexOf("between-") >= 0 ||
               pathUri[vi].indexOf("over-") >= 0 ||
               pathUri[vi].indexOf("under-") >= 0
             ) {
            // its price
            let priceFilt1 = pathUri[vi].split('over-');
            if( priceFilt1[1] ) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split('under-');
            if( priceFilt2[1] ) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split('between-');
            if( priceFilt3[1] ) {
              let priceFilt4 = priceFilt3[1].split('-and-');
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }
          // Must Include
          if (pathUri[vi].indexOf("with-") >= 0) {
            var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
            mustIncludeVal = mustIncludeArrfilt.split("-and-")
          }
          let priceFilt5 = pathUri[vi].split('type-');
          if( priceFilt5[1] ) {
            typeVal = priceFilt5[1]
          }

          // Radius
          if (pathUri[vi].indexOf("radius-") >= 0) {
              radiusVal = pathUri[vi].replace("radius-", "").replace("-miles")
          }

          // sort by
          if (pathUri[vi].indexOf("sortby-") >= 0) {
            sortVal = pathUri[vi].replace("sortby-", "")
          }
        }
      }

    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
       // areaVal = defaultArea.slug; // TODO
    }

    var areaAlias = areaVal.replaceAll(' ', '-');

    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      type: typeVal,
      radius: ((areaVal.replaceAll(' ', '-')) === defaultArea.slug && (!radiusVal || radiusVal === 3)) ? 0 : parseInt(radiusVal),
      mustInclude: mustIncludeVal,
      sortVal: sortVal,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = (params) => {

  const {areas, bedrooms, pType, type, price, mustInclude} = params;

  return {
    department: "new_developments",
    search_type: pType,
    areas: areas,
    bedroom: bedrooms,
    minPrice:  price.min,
    maxPrice:  price.max,
    building: type,
    must_Include: mustInclude,
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const propertyH1 = (params) => {
  const {areas, bedrooms, pType, type, price, extra, mustInclude} = params;

  let h1 = "";
  let building = "";

  if ('Commercial' === pType) { // TODO
    // Commercial Property [action] in [default search area] for Sale and Rent
    building = "Commercial ";

    if (type)
      building += ` ${type} `;
    else
      building += ` Property `;

    h1 += `${building} in `;

    if (areas) {
      h1 += areas;
    }

    h1 += ` for sale and rent`;

  } else {
    let building = "Property";
    if (bedrooms)
      building = "property";

    if (bedrooms) {
      let bedPlus = extra.bedPlus ? '+' : '+';
      h1 += bedrooms + bedPlus + " Bedroom ";
    }

    if (type) {
      building = `New ${_.get(buildingTypes, type, type)} `;
    } else {
      building = ` International properties `;
    }

    if (bedrooms)
      building = building.toLowerCase();

    h1 += building;
    h1 += `${ACTION_TYPES[pType]} `;

    if (areas) {
      h1 += `in `;
      let _areas = areas;
      _areas = _areas.replaceAll("-"," ");
      _areas = decodeURIComponent(_areas);
      _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
      h1 += `${_areas.replace("north","North").replace("road","Road")} `
    }

    if ( price && (price.min || price.max) )  {
      h1 += getPriceStr(price);
    }

    if ( mustInclude && mustInclude ) {
      h1 += " with "+mustInclude.join('-and-')
    }
  }

  return h1;
}
