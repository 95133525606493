import React, { useState, useEffect } from "react"
import { defaultArea } from "@constants";
import { isEmpty, get, find } from "lodash"
import { navigate } from "@reach/router"
import Select from "react-select"
import { Dropdown, ButtonGroup } from "react-bootstrap"
import { Col, Container, Row, Form } from "react-bootstrap"
import SearchResultInputBox from "../../predictive-search/SearchResultBox"
import {
  bedOptions,
  newBuildingOptions,
  newPriceminRange,
  newPricemaxRange,
  newPriceminRangeRentals,
  newPricemaxRangeRentals,
  radiusOptions,
  createSearchUrlFromParams,
  milesToMeter,
  getLatLan,
  isAreaMatch
} from "./constant"

import {
  Configure
} from "react-instantsearch-dom"

const styles = {
  control: styles => ({
    ...styles,
    backgroundColor: "#b208381c",
    border: 0,
    outline: 0,
    boxShadow: "none",
    fontSize: "14px",
    color: "#263140",
    minHeight: "3rem",
    paddingLeft: "1rem",
    marginBottom: "1rem",
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      color: "#263140",
    }
  },
}

const clickHandle = e => {
  const li = e.target.closest("li")
  li.closest("ul")
    .querySelectorAll("li")
    .forEach(item => {
      if (item !== li) {
        item.classList.remove("active")
      }
    })
  e.target.closest("li").classList.toggle("active")
}

// Sales & Rent

const RefineFilter = props => {
  const { searchParams, areaList } = props
  // console.log("searchParams => ", props)
  let minVal = get(searchParams, "price.min", "")
  let maxVal = get(searchParams, "price.max", "")
  // const [filterOpen, setFilterOpen] = useState(false);
  const [areaVal, setAreaVal] = useState(get(searchParams, "areas", ""))
  const [minpriceVal, setMinPriceVal] = useState(minVal ? minVal : "0")
  const [maxpriceVal, setMaxPriceVal] = useState(minVal ? maxVal : "0")
  const [propertyVal, setPropertyVal] = useState(
    get(searchParams, "type", null)
  )
  const [bedVal, setBedVal] = useState(get(searchParams, "bedrooms", ""))
  const [radiusVal, setRadiusVal] = useState(get(searchParams, "radius", 0) || 3)
  const [minPriceList, setMinPriceList] = useState([])
  const [maxPriceList, setMaxPriceList] = useState([])
  // const [areaList, setAreaList] = useState([])
  const [aroundLatLng, setAroundLatLng] = useState(false);
  const [sortVal, setSortVal] = useState(get(searchParams, "sortVal", ""))
  
  const [configvalues, setConfigvalues] = useState({
      hitsPerPage: 20
  })

  // Capitalize
  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  // Capitalize

  useEffect(() => {
    if (isEmpty(minPriceList)) {
      if (props.ptype === "lettings") {
        setMinPriceList(newPriceminRangeRentals)
        setMaxPriceList(newPricemaxRangeRentals)
      } else {
        setMinPriceList(newPriceminRange)
        setMaxPriceList(newPricemaxRange)
      }
    }
  }, [])

  // Update max price based on min price
  useEffect(() => {
      if (minpriceVal !== undefined && parseInt(minpriceVal)) {
        let priceItemList = newPricemaxRange;
        if (props.ptype === "lettings")
          priceItemList = newPricemaxRangeRentals

        setMaxPriceList(priceItemList.filter(
          x => (parseInt(x.value) > parseInt(minpriceVal) || !parseInt(x.value))
        ))
    } else {
        let priceItemList = newPricemaxRange;
        if (props.ptype === "lettings")
          priceItemList = newPricemaxRangeRentals
        setMaxPriceList(priceItemList)
    }
  }, [minpriceVal])

  // Update min price based on max price
  useEffect(() => {
      if (maxpriceVal !== undefined && parseInt(maxpriceVal)) {
        let priceItemList = newPriceminRange;
        if (props.ptype === "lettings")
          priceItemList = newPriceminRangeRentals
        setMinPriceList(priceItemList.filter(
          x => (parseInt(x.value) < parseInt(maxpriceVal) || !parseInt(x.value))
        ))
    } else {
        let priceItemList = newPriceminRange;
        if (props.ptype === "lettings")
          priceItemList = newPriceminRangeRentals
        setMinPriceList(priceItemList)
    }
  }, [maxpriceVal])


  useEffect(() => {
    // console.log("useEffect => ", radiusVal, areaList.length);
    if (radiusVal && areaList.length > 0) {
        let selectedArea = '';
        selectedArea = isAreaMatch(areaList, areaVal);
        let aroundLatLngTmp = aroundLatLng;
        if (!isEmpty(selectedArea)) {
            aroundLatLngTmp = getLatLan(selectedArea);
            setAroundLatLng(getLatLan(selectedArea));
        }

        if (radiusVal && !isEmpty(aroundLatLngTmp && getLatLan(selectedArea))) {
            setConfigvalues({
                ...configvalues,
                aroundLatLng: aroundLatLngTmp,
                aroundRadius: milesToMeter(radiusVal),

            })
        }

        if ((!getLatLan(selectedArea) && !isEmpty(aroundLatLngTmp))) {
            setConfigvalues({
                hitsPerPage: 20
            })
        }
    }
}, [areaList, searchParams])

  const onSubmit = e => {
    e.preventDefault()
    console.log('areaVal', areaVal)
    let params = {
      areas: areaVal,
      minPrice: minpriceVal,
      maxPrice: maxpriceVal,
      bedroom: bedVal,
      building: propertyVal,
      searchBaseUrl: props?.locationname,
      radius: radiusVal,
      areaList: areaList,
      sortVal: sortVal
      // search_type: props?.searchType,
    }
    let url = createSearchUrlFromParams(params)
    navigate(url)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // hitsPerPage={20}
  // aroundRadius={1000000}
  // // aroundLatLng={`53.37,-1.53`}
  // // aroundLatLng={`53.37,-1.53`}
  // aroundLatLng={aroundLatLng}

  let radiusAvailable = false;
  if (!isEmpty(areaList) && !isEmpty(areaVal)) {
      let sArea = isAreaMatch(areaList, areaVal);
      if (!isEmpty(sArea)) {
        radiusAvailable = true;

      }
  }

  return (
    <div className="form-wrapper-static new-homes">
       <div className="destop-filter container">
        <Form className="form-info" onSubmit={e => onSubmit(e)}>
        <Form.Group className="search-box-wrapper">
            <SearchResultInputBox
              value={(areaVal.replaceAll(' ', '-')) !== defaultArea.slug ? areaVal : ''}
              setValue={setAreaVal}
              placeHolder={`Area`}
              areaList={areaList}
              // setList={setAreaList}
            />
        
            <Select
              name="radius"
              options={radiusOptions}
              value={{
                label: radiusVal
                  ? radiusOptions.filter(c => c.value == radiusVal)[0].label
                  : "Radius",
                value: radiusVal ? radiusVal : "",
              }}
              placeholder="Radius"
              classNamePrefix={"select-opt bedroom-select-box"}
              isSearchable={false}
              onChange={e => {
                setRadiusVal(e.value)
              }}
              isDisabled={!radiusAvailable}
            />
            <i className="icon-menu-slider"></i>
          </Form.Group>
          <Form.Group className="bed-room pipe">
            <Select
              name="minprice"
              options={minPriceList}
              isSearchable={false}
              classNamePrefix={"select-opt  bedroom-select-box"}
              defaultValue={minpriceVal || "0"}
              value={minPriceList.find(
                obj => obj.value === minpriceVal
              )}
              placeholder="Min Price"
              onChange={e => {
                setMinPriceVal(e.value)
              }}
              
            />
            <i className="icon-menu-slider"></i>
          </Form.Group>
          <Form.Group className="bed-room pipe">
           <Select
              name="price"
              options={maxPriceList}
              defaultValue={maxpriceVal || "0"}
              value={maxPriceList.find(
                obj => obj.value === maxpriceVal
              )}
              placeholder="Max Price"
              classNamePrefix={"select-opt  bedroom-select-box"}
              isSearchable={false}
              onChange={e => {
                setMaxPriceVal(e.value)
              }}
              
            />
            <i className="icon-menu-slider"></i>
          </Form.Group>
          <Form.Group className="bed-room pipe">
            <Select
              name="beds"
              options={bedOptions}
              value={{
                label: bedVal
                  ? bedOptions.filter(c => c.value == bedVal)[0].label
                  : "Beds",
                value: bedVal ? bedVal : "",
              }}
              placeholder="Beds"
              classNamePrefix={"select-opt bedroom-select-box"}
              isSearchable={false}
              onChange={e => {
                setBedVal(e.value)
              }}
            />
            <i className="icon-menu-slider"></i>
          </Form.Group>

          <Form.Group className="building">
            <Select
              name="building"
              options={newBuildingOptions}
              placeholder={
                propertyVal === null || undefined || ""
                  ? "Property type"
                  : capitalize(propertyVal).replace(/-/g, " ")
              }
              isSearchable={false}
              classNamePrefix={"select-opt bedroom-select-box"}
              value={{
                label: propertyVal
                  ? get(newBuildingOptions.filter(c => c.value.toLowerCase() === propertyVal.toLowerCase()), '0.label')
                  : "Property type",
                value: propertyVal ? propertyVal : "",
              }}
              onChange={e => {
                setPropertyVal(e.value)
              }}
            />
            <i className="icon-menu-slider"></i>
          </Form.Group>
          <Form.Group className="building-btn">
          <button type="submit" className="btn btn-submit btn-update-submit">
            <i className="icon-magnifying-glass"></i>
          </button>
          </Form.Group>
        </Form>

       
      </div>
    </div>
  )
}

export default RefineFilter
