/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import { Helmet } from "react-helmet"
import { ACTION_TYPES, getPriceStr, toTitleCase, buildingTypes } from "./newhomesutils"
import { useLocation } from "@reach/router"
import { sitename } from "@constants";

const NewhomeSeoResults = ({ description, lang, meta, title, searchParams, location }) => {

    const {areas, bedrooms, pType, type, price, commType, mustInclude } = searchParams;

    const actionStr = ACTION_TYPES[pType];
    const priceStr = getPriceStr(price)
    const bedRooms = searchParams.bedrooms;
    const typeStr = _.get(buildingTypes, type, type)


    const capitalize = (s) => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }

    var search_area = `${toTitleCase(areas)}`;
    var search_areas = search_area.replaceAll('-', ' ');
    var price_range = priceStr, property_type = `${typeStr}`;
    var action = `${pType}`;
    var bedroom_text = bedrooms + " bedrooms"

    
    if(search_area){
        search_area = `in ${search_area}`;
      }

    var m_title =
        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
        `${bedrooms} Bedroom ${property_type} for ${action} ${search_area} ${price_range}` :
        (property_type !== "" && bedrooms !== "") ?
        `${bedrooms} Bedroom ${property_type} for ${action} ${search_area}` :
        (price_range !== "" && bedrooms !== "") ?
        `${bedrooms} Bedroom for ${action} ${search_area} ${price_range}` :
        (price_range !== "" && property_type !== "") ?
        `${property_type} for ${action} ${search_area} ${price_range}` :
        (bedrooms !== "") ?
        `${bedrooms} Bedroom for ${action} ${search_area}` :
        (property_type !== "") ?
        `${property_type} for ${action} ${search_area}` :
        (price_range !== "") ?
        `New home developments ${action} ${search_area} ${price_range}` :
        `New home developments ${action} ${search_area}`;

    let metaTitle = title || m_title;

    var m_description=
        (price_range !== "" && property_type !== "" && bedrooms !== "") ?
        `Find new ${property_type} ${actionStr} ${search_area} with more than ${bedroom_text} ${price_range} with Redbrik. Contact one of our new homes professional today to get assistance in finding the best property in ${search_area}.`:
        (property_type !== "" && bedrooms !== "") ?
        `Find the latest new ${property_type} ${actionStr} ${search_area} with more than ${bedroom_text} bedrooms. Contact Redbrik estate agents and letting agents in ${search_area}, to arrange a viewing.`:
        (price_range !== "" && bedrooms !== "") ?
        `Explore the list of new homes ${actionStr} ${search_area} with more than ${bedroom_text} ${price_range} with Redbrik and Request a viewing for the property that interests you.`:
        (price_range !== "" && property_type !== "") ?
        `Check the latest collection of new ${property_type} ${actionStr} ${search_area} ${price_range} with Redbrik estate agents and letting agents in ${search_area}.`:
        (bedrooms !== "") ?
        `Find new homes ${actionStr} with more than ${bedroom_text} ${search_area} here. Contact Redbrik's new home professionals ${search_area} today to get assistance.`:
        (property_type !== "") ?
        `Searching for a newly built ${property_type} ${actionStr} ${search_area}? Redbrik's new homes professionals are here to help.`:
        (price_range !== "") ?
        `Locate the best new homes ${actionStr} ${search_area} ${price_range} with Redbrik Estate agents. Book an appointment with one of our new homes professional ${search_area}.`:
        `Explore our stunning range of new home developments ${actionStr} ${search_areas}. Contact our new homes team to find the right property for ${action} ${search_areas}.`;

    description = m_description;

    const { pathname } = useLocation()

    var curentUrl = '';
    curentUrl = process.env.GATSBY_SITE_URL+pathname;

    // set no index for result pagination
    let hasPagination = false;
    if (pathname !== undefined && pathname) {
        let pageStr = pathname.split('page-');
        hasPagination = pageStr[1] || false;
        if (pageStr[0] !== undefined) {
            curentUrl = process.env.GATSBY_SITE_URL + pageStr[0];
        }
    }

    if (!curentUrl.endsWith('/'))
            curentUrl = curentUrl + '/';

    //remove multiple slashes
    curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1");
    //set lowercase url
    curentUrl = curentUrl.toLowerCase();

    let metaItems = [
        {
            name: `description`,
            content: description,
        },
        {
            property: `og:title`,
            content: metaTitle,
        },
        {
            property: `og:description`,
            content: description,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `og:url`,
            content: curentUrl,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: `${sitename}`,
        },
        {
            name: `twitter:title`,
            content: metaTitle,
        },
        {
            name: `twitter:description`,
            content: description,
        },
    ].concat(meta);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            link={[
                {
                    href: curentUrl,
                    rel: "canonical"
                }
            ]}
            title={metaTitle}
            titleTemplate={`%s | ${sitename}`}
            meta={metaItems}
        />
    )
}

NewhomeSeoResults.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    title: ``,
    searchParams: [],
}

NewhomeSeoResults.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    searchParams: PropTypes.any,
}

export default NewhomeSeoResults
